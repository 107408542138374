<template>
    <main class="container">
        <!-- <Navigation /> -->
        <code>[navigation potentially]</code>

        <slot />

        <span class="flex flex-1"></span>
    </main>
</template>
